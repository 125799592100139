import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Sidebar from "../Sidebar/Sidebar";
import { SnippetsOutlined } from "@ant-design/icons";
import Footer from "../Footer/Footer";
import { updateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";
import toast from "react-hot-toast";
import r0 from "../../Assets/Images/R0.png";
import r1 from "../../Assets/Images/R1.png";
import r2 from "../../Assets/Images/R2.png";
import logo from "../../Assets/Images/logo.png";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dash, setdataArray] = useState([]);
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const [currenttimer, setcurrenttimer] = useState(0);
  const [Days_here_booster, setDays_here_booster] = useState(0);
  const [Hours_here_booster, setHours_here_booster] = useState(0);
  const [Munits_here_booster, setMunits_here_booster] = useState(0);
  const [Seconds_booster, setSeconds_booster] = useState(0);
  const [liverate, setliverate] = useState("");
  const [News, setNews] = useState([]);

  const Dashboard_API = async () => {
    try {
      let responce = await API?.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      //console.log("responceDashboard", responce);

      setdataArray(responce);
      setliverate("1 CELEX = " + responce.USDinToken + " USDT");

      let responcenews = await API.get("GetNews", {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responcenews = responcenews.data.data[0];
      //console.log("GetNews-->", responcenews);
      setNews(responcenews);
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({ aexnglobalAuth: false, userId: null, jwtToken: null })
        );
        navigate("/");
      }
    }
  };

  const GetCurrentCycleTime = async () => {
    try {
      let responce = await API?.get(`getCurrentCycleTime?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data;
      //console.log("currentCycleTime", responce);
      //localStorage.setItem("Timer2", responce);
      setcurrenttimer(responce);
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({ aexnglobalAuth: false, userId: null, jwtToken: null })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
    GetCurrentCycleTime();
  }, []);

  useEffect(() => {
    if (currenttimer) {
      setInterval(() => {
        timer2();
      }, 1000);
    }
  }, [currenttimer]);

  const Timer2 = currenttimer; //localStorage.getItem("Timer2");
  //console.log("Timer2", Timer2);
  const timer2 = async () => {
    try {
      var currentDateTime = new Date();
      //console.log("currentDateTime", currentDateTime);
      let resultInSeconds = currentDateTime.getTime() / 1000;
      let topupInSeconds = new Date(Timer2).getTime() / 1000;
      let Time_here = topupInSeconds - resultInSeconds;
      let TimeFinal = parseInt(Time_here);
      if (TimeFinal <= 0 || Timer2 === "") {
        setDays_here_booster(0);
        setHours_here_booster(0);
        setMunits_here_booster(0);
        setSeconds_booster(0);
      } else {
        let days = parseInt(TimeFinal / 86400);
        setDays_here_booster(days);
        TimeFinal = TimeFinal % 86400;
        let hours = parseInt(TimeFinal / 3600);
        setHours_here_booster(hours);
        TimeFinal %= 3600;
        let munites = parseInt(TimeFinal / 60);
        setMunits_here_booster(munites);
        TimeFinal %= 60;
        let second_here = parseInt(TimeFinal);
        setSeconds_booster(second_here);
      }
    } catch (e) {
      console.log("Error While Timer", e);
    }
  };

  let URL = window.location.origin;

  const CopyReferralLink = () => {
    const text = `${URL}/register?userid=${userId}`;
    //  `${URL}/registration?userid=${user}`
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const CopyWalletAddress = () => {
    const text = `https://bscscan.com/address/${dash.walletAddress}`;
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const getImageSrc = (rank) => {
    switch (rank) {
      case 1:
        return r1;
      case 2:
        return r2;
      default:
        return r0;
    }
  };

  const OnHandleWalletAddress = () => {
    window.open(`https://bscscan.com/address/${dash.walletAddress}`, "_blank");
  };

  const OnHandlePancakeSwap = () => {
    window.open(
      `https://pancakeswap.finance/swap?inputCurrency=0xAE9e48C204b2bB47DA7Aaacd4Cb110D79888A9b0&outputCurrency=0x55d398326f99059fF775485246999027B3197955`,
      "_blank"
    );
  };

  const OnHandleActivationLink = () => {
    navigate("/Activate");
  };

  const OnHandleWithdrawalLink = () => {
    navigate("/Working_Withdrawal");
  };

  const OnHandleRocLink = () => {
    navigate("/ReleaseRoiIncome");
  };

  return (
    <>
      <div className="nft_theme">
        <Sidebar />
        <div className="main-content app-content">
          {/* <a
            href="#top"
            id="back-to-top"
            className="back-to-top rounded-circle shadow">
            <img src="https://metaswift.ai/images/robot_icon.png" />
          </a> */}
          <div className="main-container container-fluid">
            <div className="breadcrumb-header justify-content-between">
              <div className="row">
                <div className="col-12">
                  <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1 h3">
                      DASHBOARD
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-md-center mx-0">
              <div className="col-md-5">
                <div className="affiliateLink w-100">
                  <h6
                    className="mb-2 fw-bold text-white"
                    style={{ fontsize: "15px" }}
                  >
                    Wallet Address
                  </h6>
                  <div className="d-block align-items-center mb-2 w-100">
                    <div className="form-group w-100">
                      <div className="input-group mb-0" id="textToCopy">
                        <input
                          style={{
                            border: "1px solid #0cd3ce",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          type="text"
                          id="textToCopy"
                          name="myInput2"
                          className="form-control fw-bold text-white"
                          readonly="readonly"
                          value={dash.walletAddress}
                          onClick={() => OnHandleWalletAddress()}
                        />
                        <div
                          onClick={CopyWalletAddress}
                          style={{
                            MarginTop: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span text="button" className="input-group-text p0">
                            <SnippetsOutlined />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="affiliateLink">
                  <h6 className="mb-2 fw-bold" style={{ fontsize: "15px" }}>
                    Live Rate
                  </h6>
                  <div className="d-block align-items-center mb-2">
                    <div className="form-group">
                      <div className="input-group mb-0">
                        <input
                          style={{
                            border: "1px solid #0cd3ce",
                            fontSize: "20px",
                          }}
                          type="text"
                          id="txtchangevalue"
                          readonly="readonly"
                          class="form-control fw-bold"
                          value={liverate}
                        />
                        <input
                          style={{ border: "1px solid #0cd3ce" }}
                          type="hidden"
                          id="liverate"
                          class="form-control"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="affiliateLink">
                  <div className="d-block align-items-center mb-2">
                    <div className="form-group">
                      <div className="input-group mb-0 mt-4">
                        <input
                          style={{
                            border: "1px solid #0cd3ce",
                            fontSize: "20px",
                            width: "50%",
                          }}
                          type="button"
                          id="txtchangevalue"
                          class="btn btn-success"
                          value="Swap"
                          onClick={() => OnHandlePancakeSwap()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-md-center mx-0">
              <div className="col-md-3">
                <div className="affiliateLink">
                  <div className="d-block align-items-center mb-2">
                    <div className="form-group">
                      <div className="input-group mb-0 mt-4">
                        <input
                          style={{
                            border: "1px solid #0cd3ce",
                            fontSize: "20px",
                            width: "50%",
                          }}
                          type="button"
                          id="txtchangevalue"
                          class="btn btn-success"
                          value="Activation"
                          onClick={() => OnHandleActivationLink()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="affiliateLink">
                  <div className="d-block align-items-center mb-2">
                    <div className="form-group">
                      <div className="input-group mb-0 mt-4">
                        <input
                          style={{
                            border: "1px solid #0cd3ce",
                            fontSize: "20px",
                            width: "50%",
                          }}
                          type="button"
                          id="txtchangevalue"
                          class="btn btn-success"
                          value="Withdrawal"
                          onClick={() => OnHandleWithdrawalLink()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="affiliateLink">
                  <div className="d-block align-items-center mb-2">
                    <div className="form-group">
                      <div className="input-group mb-0 mt-4">
                        <input
                          style={{
                            border: "1px solid #0cd3ce",
                            fontSize: "20px",
                            width: "50%",
                          }}
                          type="button"
                          id="txtchangevalue"
                          class="btn btn-success"
                          value="Claim ROC"
                          onClick={() => OnHandleRocLink()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 overflow-hidden">
              <div className="col-lg-4">
                <div className="row mx-0 overflow-hidden">
                  <div className="col-lg-12">
                    <div className="card h-100">
                      <div className="card-body">
                        <div className="bg-label-primary rounded-3 text-center mb-3">
                          <div className="row mx-0 overflow-hidden">
                            <div
                              className="col-lg-6"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className="img-fluid"
                                //src={getImageSrc(dash.MyRank)}
                                src={logo}
                                alt="Card Img"
                                width={140}
                                style={{ display: "block" }}
                              />
                            </div>
                            <div className="col-lg-6">
                              <div className="mainss">
                                <div className="text-center">
                                  <div className="text-center ">
                                    <h4
                                      className="mb-0 text-nowrap"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {dash.CurrentPackage}
                                    </h4>
                                    <p
                                      style={{ "font-size": "large" }}
                                      className="userskilTit d-flex justify-content-center"
                                    >
                                      CURRENT PACKAGE
                                    </p>
                                  </div>
                                  <div className="text-center ">
                                    <h4
                                      className="mb-0 text-nowrap"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {dash.roipercent} %
                                    </h4>
                                    <p
                                      style={{ "font-size": "large" }}
                                      className="userskilTit d-flex justify-content-center"
                                    >
                                      ROC
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3 mx-0 overflow-hidden">
                          <div className="col-12">
                            <div className="userskil">
                              {/* <div className="userskilTit">
                                <span>COMPLETED</span>
                                <span>TOTAL</span>
                              </div> */}
                              {/* <div
                                className="userskilOne"
                                style={{
                                  backgroundcolor: "rgba(255, 255, 255, 0.46)",
                                }}>
                                <div
                                  className="userskilFill text-white"
                                  style={{
                                    opacity: "0.9",
                                    backgroundcolor: "transparent",
                                    width: "100%",
                                  }}>
                                  <span className="text-white">
                                    TotalLeg out of TotalTarget
                                  </span>
                                </div>
                              </div> */}
                              <div className="LegOneSkill">
                                <h6 className="userskilTit">LEG 1</h6>
                                <div className="LegOneSkillProgress">
                                  <div
                                    className="userskilOne"
                                    style={{
                                      backgroundcolor:
                                        "rgba(255, 255, 255, 0.46)",
                                    }}
                                  >
                                    <div
                                      className="userskilFill text-white"
                                      style={{ opacity: "1", width: "100%" }}
                                    >
                                      <span className="text-white">
                                        {dash.Leg1}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="LegOneSkill">
                                <h6 className="userskilTit">LEG 2</h6>
                                <div className="LegOneSkillProgress">
                                  <div className="userskilOne">
                                    <div
                                      className="userskilFill text-white "
                                      style={{ opacity: "1", width: "100%" }}
                                    >
                                      <span className="">{dash.Leg2}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="LegOneSkill">
                                <h6 className="userskilTit">ACTIVE CREW</h6>
                                <div className="LegOneSkillProgress">
                                  <div className="userskilOne">
                                    <div
                                      className="userskilFill text-white "
                                      style={{ opacity: "1", width: "100%" }}
                                    >
                                      <span className="">
                                        {dash.directlegcount}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="LegOneSkill">
                                <h6 className="userskilTit">OTHERS</h6>
                                <div className="LegOneSkillProgress">
                                  <div className="userskilOne">
                                    <div
                                      className="userskilFill text-white "
                                      style={{ opacity: "1", width: "100%" }}>
                                      <span
                                        className=""
                                        style={{ color: "rgb(255, 255, 255)" }}>
                                        TotalLeg3
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              {/* <div className="LegOneSkill">
                                <h6 className="userskilTit">Cycle</h6>
                                <div className="LegOneSkillProgress">
                                  <div className="userskilOne">
                                    <div
                                      className="userskilFill text-white "
                                      style={{ opacity: "1", width: "100%" }}>
                                      <span
                                        className=""
                                        style={{ color: "rgb(255, 255, 255)" }}>
                                        Click To Release Cycle
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row mx-0 overflow-hidden">
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <small className="d-block mb-1 colors card-heading">
                            My Volunteer
                          </small>
                          <i className="fa fa-renren ims"></i>
                        </div>
                        <div className="row overflow-hidden mx-0">
                          <div className="col-4">
                            <h5 className="mb-0 pt-1 text-nowrap">
                              {dash.TotalReferral}
                            </h5>
                            <small className="text-muted1">Total</small>
                          </div>
                          <div className="col-4">
                            <div className="divider divider-vertical"></div>
                          </div>
                          <div className="col-4 text-end">
                            <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                              {dash.TotalActiveReferral}
                            </h5>
                            <small className="text-muted1">Active</small>
                          </div>
                        </div>
                        <div className="w3-light-grey">
                          <div
                            style={{
                              background: "#4CAF50",
                              height: "11px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <small className="d-block mb-1 colors card-heading">
                            My Crew
                          </small>
                          <i className="fa fa-users ims"></i>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <h5 className="mb-0 pt-1 text-nowrap">
                              {dash.TotalTeam}
                            </h5>
                            <small className="text-muted1">Total</small>
                          </div>
                          <div className="col-4">
                            <div className="divider divider-vertical"></div>
                          </div>
                          <div className="col-4 text-end">
                            <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                              {dash.TotalActiveTeam}
                            </h5>
                            <small className="text-muted1">Active</small>
                          </div>
                        </div>
                        <div className="w3-light-grey">
                          <div
                            style={{
                              background: "#4CAF50",
                              height: "11px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <small className="d-block mb-1 colors card-heading">
                            Crew Business
                          </small>
                          <i className="fa fa-tachometer ims"></i>
                        </div>
                        <div className="row">
                          {/* <div className="col-4">
                            <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                            <small className="text-muted1">Total</small>
                          </div>
                          <div className="col-4">
                            <div className="divider divider-vertical"></div>
                          </div> */}
                          <div className="col-12 text-end">
                            <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                              {dash.TeamBusiness}
                            </h5>
                            <small className="text-muted1">Total</small>
                          </div>
                        </div>
                        <div className="w3-light-grey">
                          <div
                            style={{
                              background: "#4CAF50",
                              height: "11px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <small className="d-block mb-1 colors card-heading">
                            Grade
                          </small>
                          <i className="fa fa-briefcase ims"></i>
                        </div>
                        <div className="row">
                          {/* <div className="col-4">
                            <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                            <small className="text-muted1">Total</small>
                          </div> */}
                          {/* <div className="col-4">
                            <div className="divider divider-vertical"></div>
                          </div> */}
                          <div className="col-12 text-end">
                            <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">
                              {dash.MyRankName}
                            </h5>
                            <small className="text-muted1">Grade</small>
                          </div>
                        </div>
                        <div className="w3-light-grey">
                          <div
                            style={{
                              background: "#4CAF50",
                              height: "11px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-col">
                            <div className="card-title mb-auto">
                              <h5 className="mb-1 text-nowrap" />
                              <h4 className="mb-2 fontss card-title card-heading">
                                Component Cycle Day
                              </h4>
                              <p className="small1" />
                              <h5
                                className="mb-0 me-2 numberfont  tx-23 "
                                style={{
                                  color: "rgb(247, 246, 109)",
                                  marginLeft: "58%",
                                  fontSize: 30,
                                  display: "contents",
                                }}
                              >
                                {
                                  currenttimer === "Time is over"
                                    ? "Time is over"
                                    : currenttimer ===
                                      "Time start after activation !!"
                                      ? "Time start after activation !!"
                                      : Days_here_booster + " Day "
                                  // Hours_here_booster +
                                  // "h " +
                                  // Munits_here_booster +
                                  // "m " +
                                  // Seconds_booster +
                                  // "s"
                                }
                              </h5>
                              <p />
                              <div className="d-flex">
                                <p className="purple1">
                                  <span>{dash.MyCurrentCycle}</span>
                                </p>
                                <span>Current Cycle</span>
                              </div>
                            </div>
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="">
                          <div className="">
                            <div className="card-title mb-auto">
                              <h5 className="mb-2 text-nowrap card-title card-heading">
                                Affiliate Link
                              </h5>
                              <div className="chart-statistics">
                                <div className="d-flex justify-content-between">
                                  <div className="inputsssss mb-0">
                                    <input
                                      type="text"
                                      id="myInput1"
                                      className="form-control txt_scrl"
                                      value={`${URL}/register?userid=${userId}`}
                                    />
                                  </div>
                                  <div
                                    onClick={CopyReferralLink}
                                    style={{
                                      MarginTop: "10px",
                                    }}
                                  >
                                    <span
                                      text="button"
                                      className="input-group-text p0"
                                    >
                                      <SnippetsOutlined />
                                    </span>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <i className="text-white fa fa-facebook iconss"></i>
                                  <i className="text-white fa fa-instagram iconss"></i>
                                  <i className="text-white fa fa-telegram iconss"></i>
                                  <i className="text-white fa fa-twitter iconss"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading">
                          Grade Earning
                        </small>
                      </div>
                      <div className="row g-3">
                        {/* <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Used</small>
                        </div> */}
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.LevelIncome}
                          </h5>
                          <small className="text-muted1">Balance</small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                            // width: `${
                            //   (dash.UsedActivationFund * 100) /
                            //   dash.TotalActivationFund
                            // }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading">
                          ROC Earning
                        </small>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.Roi_incomeTotal}
                          </h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.Roi_incomeHold}
                          </h5>
                          <small className="text-muted1">Hold</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.Roi_income}
                          </h5>
                          <small className="text-muted1">Released</small>
                        </div>
                        {/* <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.Roi_income}
                          </h5>
                          <small className="text-muted1">
                            {dash.Roi_incomeHold}
                          </small>
                        </div> */}
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className=" d-block mb-1 colors card-heading">
                          KINGSHIP POOL
                        </small>
                      </div>
                      <div className="row">
                        {/* <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Used</small>
                        </div> */}
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.CTOIncome}
                          </h5>
                          <small className="text-muted1">Balance</small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className=" d-block mb-1 colors card-heading">
                          INFINITY POOL
                        </small>
                      </div>
                      <div className="row">
                        {/* <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Used</small>
                        </div> */}
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.CTOIncome2}
                          </h5>
                          <small className="text-muted1">Balance</small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading">
                          Reward Income
                        </small>
                      </div>
                      <div className="row g-3">
                        {/* <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Used</small>
                        </div> */}
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.Lifetimereward}
                          </h5>
                          <small className="text-muted1">Balance</small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                            // width: `${
                            //   (dash.UsedActivationFund * 100) /
                            //   dash.TotalActivationFund
                            // }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading">
                          Total Earning
                        </small>
                      </div>
                      <div className="row g-3">
                        {/* <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Used</small>
                        </div> */}
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.TotalIncome}
                          </h5>
                          <small className="text-muted1">Balance</small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                            // width: `${
                            //   (dash.UsedActivationFund * 100) /
                            //   dash.TotalActivationFund
                            // }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading">
                          Withdrawal
                        </small>
                      </div>
                      <div className="row g-3">
                        {/* <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Used</small>
                        </div> */}
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.WithDrawal_Amount}
                          </h5>
                          <small className="text-muted1">Balance</small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                            // width: `${
                            //   (dash.UsedActivationFund * 100) /
                            //   dash.TotalActivationFund
                            // }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className="d-block mb-1 colors card-heading">
                          Available Balance
                        </small>
                      </div>
                      <div className="row g-3">
                        {/* <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Used</small>
                        </div> */}
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.Net_Balance}
                          </h5>
                          <small className="text-muted1">Balance</small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                            // width: `${
                            //   (dash.UsedActivationFund * 100) /
                            //   dash.TotalActivationFund
                            // }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className=" d-block mb-1 colors card-heading">
                          Claimed Capital
                        </small>
                      </div>
                      <div className="row">
                        {/* <div className="col-4">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Total</small>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mb-0 pt-1 text-nowrap">0</h5>
                          <small className="text-muted1">Used</small>
                        </div> */}
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.ClaimedInvestmentAmount}
                          </h5>
                          <small className="text-muted1">Balance</small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className=" d-block mb-1 colors card-heading">
                          Reward Leg Business
                        </small>
                      </div>
                      <div className="row">
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.LegBusiness}
                          </h5>
                          <small className="text-muted1"></small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className=" d-block mb-1 colors card-heading">
                          Reward Other Leg Business
                        </small>
                      </div>
                      <div className="row">
                        <div className="col-12 text-end">
                          <h5 className="mb-0 pt-1 text-nowrap">
                            {dash.Other_LegBusiness}
                          </h5>
                          <small className="text-muted1"></small>
                        </div>
                      </div>
                      <div className="w3-light-grey">
                        <div
                          style={{
                            background: "#4CAF50",
                            height: "11px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <small className=" d-block mb-1 colors card-heading">
                          News
                        </small>
                      </div>
                      <div className="h-100">
                        <marquee
                          className="newsmarpuee"
                          //width="40%"
                          direction="up"
                          height="170px"
                          scrollamount="3"
                        >
                          {News.map((item, index) => (
                            <h6 className="text-white">{item.content}</h6>
                          ))}
                        </marquee>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="prgs">
                    <img
                      src="/Assets/images/redscoter.png"
                      id="dynamicwidth"
                      alt=""
                      style={{ marginLeft: "112.86%" }}
                    />
                    <div id="myProgress">
                      <div
                        id="myBar"
                        style={{
                          backgroundColor: dash.EarnPer > 90 ? "red" : "green",
                          width: `${dash.EarnPer}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    class="text_color text-center mt-2"
                    style={{ fontsize: "medium" }}
                  >
                    <span style={{ "font-size": "large" }}>
                      You have earned Total {dash.EarnPer} % ({dash.EarnAmount}{" "}
                      USD) out of 300 % ({dash.MaxIncome} USD) of total package
                      ({dash.MyPackage}).
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        {/* <!---end----> */}
      </div>
    </>
  );
}
